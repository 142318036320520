import React from 'react';
import TopSection from './components/top/TopSection';
import AboutUs from './components/about/AboutUs';
import Sierra from './components/sierra/Sierra';
import Sections from './components/sections/Sections';
import Sponsors from './components/sponsors/Sponsors';
import Footer from './components/footer/Footer';
import JoinUs from './components/join/JoinUs';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="*" element={<><TopSection /><AboutUs /><Sierra /><Sections /><Sponsors /><Footer /></>}></Route>
        <Route path="/join" element={<JoinUs />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
