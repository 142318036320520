import React, {useEffect} from 'react';
import styles from "./JoinUs.module.css";
import './nice-forms.scss';
import Logo from '../../images/sky2.png';
import { IoArrowBackOutline   } from "react-icons/io5";
import Starfield from 'react-starfield';


const JoinUs = () => {

  useEffect(()=>{
    document.title = 'SZTE Skywalkers - Jelentkezés';
    if (localStorage.getItem('szteskyregister') === "registered") {
      document.getElementById("rightcontainer").style.opacity = 0;
      document.getElementById("leftcontainer").style.width = "100%";
      document.getElementById("change").innerHTML = "Köszönjük, hogy jelentkeztél!";
    }
  }, [])

  const handleSubmit = async (e) => {
    const requestData = {
      firstname: document.getElementById("firstname").value,
      lastname: document.getElementById("lastname").value,
      email: document.getElementById("email").value,
      phone: document.getElementById("phone").value,
      division: document.getElementById("division").value,
      study: document.getElementById("study").value,
      motivation: document.getElementById("motivation").value,
    }
    if (requestData.firstname!=="" && requestData.lastname!=="" && requestData.email!=="" && requestData.phone!=="" && requestData.study!=="" && requestData.motivation!=="") {
      handleSzak();
      const requestJson = JSON.stringify(requestData);
      try {
        const response = await fetch("https://eog4wppz4eqlobb.m.pipedream.net", {
          method: "POST",
          body: requestJson,
        });
        const responseText = await response.status;
        if (responseText == "200") {
          document.getElementById("rightcontainer").style.opacity = 0;
          document.getElementById("leftcontainer").style.width = "100%";
          document.getElementById("change").innerHTML = "Köszönjük, hogy jelentkeztél!";
          localStorage.setItem('szteskyregister', "registered");
        }
      } catch (ex) {
        console.error("POST error!");
      }
    } else {
      alert("Kérlek tölts ki minden mezőt!");
    }
  };

  const handleSzak = async (e) => {
    const requestData = {
      firstname: document.getElementById("firstname").value,
      lastname: document.getElementById("lastname").value,
      email: document.getElementById("email").value,
      study: document.getElementById("study").value,
      motivation: document.getElementById("motivation").value,
    }
    const requestJson = JSON.stringify(requestData);
    try {
      if (document.getElementById("division").value === "Mechanika") {
        const response = await fetch("https://eortgkhghqozr9q.m.pipedream.net", {
          method: "POST",
          body: requestJson,
        });
      }   
      if (document.getElementById("division").value === "Elektronika") {
        const response = await fetch("https://eohwmpy70f3cyr2.m.pipedream.net", {
          method: "POST",
          body: requestJson,
        });
      }   
      if (document.getElementById("division").value === "Szoftver") {
        const response = await fetch("https://eor2t61r31xp9r0.m.pipedream.net", {
          method: "POST",
          body: requestJson,
        });
      }      
    } catch (ex) {
      console.error("POST error!");
    }
  };

  return (
    <div id="container" className={styles.container}>
        <Starfield
                  starCount={8000}
                  starColor={[255, 255, 255]}
                  speedFactor={0.025}
                  backgroundColor="#000000"
        />
        <div id="leftcontainer" className={styles.leftcontainer}>          
          <a href="*" className={styles.back}><IoArrowBackOutline  /></a> 
          <div id="head" className={styles.head}>
            <h1 style={{fontWeight: 400}}>SZTE</h1>
            <h1 style={{fontWeight: 100}}>Skywalkers</h1>   
          </div>
          <div id="information" className={styles.information}>
            <a id="change" style={{transition:"all 2s ease-in"}} className={styles.welcome}>Csatlakozz hozzánk!</a>
            <a id="change" style={{transition:"all 2s ease-in"}} className={styles.welcome2}>Oldalunk jelenleg nem támogatja a telefonos jelentkezés leadását. Kérlek keresd fel oldalunkat számítógépről.</a>
          </div>            
        </div>
        <div id="rightcontainer" className={styles.rightcontainer}>
          <div id="form" className={styles.form}>
            <div id="formleft" className={styles.formleft}>
              <>          
                <div style={{position:"relative", left:"95%", transform:"translateX(-50%)"}} class="nice-form-group">
                  <label>Keresztnév</label>
                  <input id="firstname" style={{width: "15vw"}} type="text" placeholder="Ide írd a keresztneved" required/>
                </div>
                <div style={{position:"relative", left:"95%", transform:"translateX(-50%)"}} class="nice-form-group">
                  <label>Email</label>
                  <input id="email" style={{width: "15vw"}} type="email" placeholder="skywalker@gmail.com" class="icon-left" required/>
                </div>
                <div style={{position:"relative", left:"95%", transform:"translateX(-50%)"}} class="nice-form-group">
                  <label>Melyik részlegnél dolgoznál?</label>
                  <select id="division" style={{width: "15vw"}}>
                    <option>Mechanika</option>
                    <option>Elektronika</option>
                    <option>Szoftver</option>
                  </select>                  
                </div>
              </>
            </div>
            <div id="formright" className={styles.formright}>
              <>          
                <div style={{position:"relative", left:"15%", transform:"translateX(-10%)"}} class="nice-form-group">
                  <label>Vezetéknév</label>
                  <input id="lastname" style={{width: "15vw"}} type="text" placeholder="Ide írd a vezetékneved" required/>
                </div>
                <div style={{position:"relative", left:"15%", transform:"translateX(-10%)"}} class="nice-form-group">
                  <label>Telefonszám</label>
                  <input id="phone" style={{width: "15vw"}} type="tel" placeholder="Add meg a telefonszámod" class="icon-left" required/>
                </div>
                <div style={{position:"relative", left:"15%", transform:"translateX(-10%)"}} class="nice-form-group">
                  <label>Tanulmányok</label>
                  <input id="study" style={{width: "15vw"}} type="text" placeholder="Melyik karon tanulsz?" required/>
                </div>
              </>
            </div>
            <>
              <div style={{position:"absolute", left:"50%", transform:"translateX(-50%)", top:"45.5vh"}} class="nice-form-group">
                <label style={{transform:"translateX(-5%)"}}>Motivációs levél</label>
                <small style={{width:"110%", wordBreak:"break-word", whiteSpace:"normal", lineHeight:"1.25em", transform:"translateX(-4.5%)"}}>Kérünk fogalmazd meg, hogy miért szeretnél csapatunkhoz csatlakozni? Miért ezt a feladatkört választottad? (Min. 150 szó)</small>
                <textarea id="motivation" maxLength="1970" style={{width: "110%", height: "15em", transform:"translateX(-4%)"}}></textarea>
              </div>
            </>            
          </div>
          <a onClick={() => {handleSubmit();}} className={styles.submit}>Jelentkezés</a>
        </div>
    </div>
  );
}

export default JoinUs;
