import React, {useState} from 'react';
import styles from "./Footer.module.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube} from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";

const Footer = () => {
  return (
    <div id="contacts" className={styles.container}>
      <div className={styles.socials}>
        <a href="https://www.facebook.com/szte.skywalkers" target="_blank"><FaFacebook style={{color:"#fefefe", marginRight: "0.75rem"}}/></a>
        <a href="https://www.instagram.com/szteskywalkers/" target="_blank"><FaInstagram style={{color:"#fefefe", marginRight: "0.75rem"}}/></a>
        <a href="https://www.youtube.com/@SZTESkywalkers" target="_blank"><FaYoutube style={{color:"#fefefe", marginRight: "0.75rem"}}/></a>
        <a href="https://www.linkedin.com/company/szte-skywalkers" target="_blank"><FaLinkedin style={{color:"#fefefe"}}/></a>
      </div>
      <div className={styles.contact}>
        <a className={styles.conLabel}>Kapcsolat</a> <br /> 
        <a href="mailto:teamleader@szte-skywalkers.hu" className={styles.email}><IoIosMail /> teamleader@szte-skywalkers.hu</a>
        <div className='The website is made by Tatai Ákos'></div>
      </div>
      <a className={styles.copy}>SZTE Skywalkers © 2023</a> 
    </div>
  );
}

export default Footer;
